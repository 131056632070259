import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";

export interface User {
    name: string;
    surname: string;
    email: string;
    password: string;
    api_token: string;
}

export interface UserAuthInfo {
    errors: unknown;
    user: User;
    isAuthenticated: boolean;
    users: unknown;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
    errors = {};
    user = {} as User;
    isAuthenticated = !!JwtService.getToken();
    users = {};
    token = "";
    downloadUploadTraffic = {};
    paymentsList = null;
    paymentsStatistics = null;
    requestsData = null;

    /**
     * Get current user object
     * @returns User
     */
    get currentUser(): User {
        return this.user;
    }

    /**
     * Verify user authentication
     * @returns boolean
     */
    get isUserAuthenticated(): boolean {
        return this.isAuthenticated;
    }

    /**
     * Get authentification errors
     * @returns array
     */
    get getErrors() {
        return this.errors;
    }

    get getDownloadUploadUserTraffic() {
        return this.downloadUploadTraffic;
    }

    get getUserPaymentsList() {
        return this.paymentsList;
    }

    get getPaymentsStatistics() {
        return this.paymentsStatistics;
    }

    get getUserRequestsData() {
        return this.requestsData;
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = {...error};
    }

    @Mutation
    [Mutations.SET_DOWNLOAD_UPLOAD_USER_TRAFFIC](data) {
        this.downloadUploadTraffic = data;
    }

    @Mutation
    [Mutations.SET_AUTH](users_data) {
        this.isAuthenticated = true;

        this.users = users_data;
        if (users_data.tv != null) {
            this.user = users_data.tv;
            this.token = users_data.api_key;
        }

        this.errors = {};

        JwtService.saveToken(this.token);
    }

    @Mutation
    [Mutations.SET_USER](user) {
        this.user = user;
    }

    @Mutation
    [Mutations.SET_PASSWORD](password) {
        this.user.password = password;
    }

    @Mutation
    [Mutations.PURGE_AUTH]() {
        this.isAuthenticated = false;
        this.user = {} as User;
        this.errors = [];
        JwtService.destroyToken();
    }

    @Mutation
    [Mutations.SET_USER_PAYMENTS_LIST](data) {
        this.paymentsList = data.payments;
        this.paymentsStatistics = data.statistics;
    }

    @Mutation
    [Mutations.SET_USER_REQUESTS_DATA](data) {
        this.requestsData = data;
    }

    @Action
    [Actions.LOGIN](credentials) {
        return ApiService.post("usertvs/login-check", credentials)
            .then(({data}) => {
                if (data.errors != undefined && Object.keys(data!.errors).length > 0) {
                    this.context.commit(Mutations.SET_ERROR, data.errors);
                } else {
                    this.context.commit(Mutations.SET_AUTH, data);
                }
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.LOGOUT]() {
        this.context.commit(Mutations.PURGE_AUTH);
    }

    @Action
    [Actions.FORGOT_PASSWORD](payload) {
        return ApiService.post("forgot_password", payload)
            .then(() => {
                this.context.commit(Mutations.SET_ERROR, {});
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.VERIFY_AUTH](payload) {
        if (JwtService.getToken()) {
            //ApiService.setHeader();
            ApiService.post("usertvs/verify-user", payload)
                .then(({data}) => {
                    if (
                        data.errors != undefined &&
                        Object.keys(data!.errors).length > 0
                    ) {
                        this.context.commit(Mutations.SET_ERROR, data.errors);
                        this.context.commit(Mutations.PURGE_AUTH);
                    } else {
                        this.context.commit(Mutations.SET_AUTH, data);
                    }
                })
                .catch(({response}) => {
                    this.context.commit(Mutations.SET_ERROR, response.data.errors);
                    this.context.commit(Mutations.PURGE_AUTH);
                });
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }

    @Action
    [Actions.NO_MONEY_SEND_REQUEST](payload) {
        if (JwtService.getToken()) {
            ApiService.post("usertvs/no-money-request", payload).then(({data}) => {
                if (data.success) {
                    this.context.commit(Mutations.SET_USER, data.data);
                }

                if (payload.fn != undefined) {
                    payload.fn(data);
                }
            });
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }

    @Action
    [Actions.USER_PAYMENT_LIST](payload) {
        if (JwtService.getToken()) {
            ApiService.post("usertvs/get-users-payments-data", payload).then(
                ({data}) => {
                    if (data.success) {
                        this.context.commit(Mutations.SET_USER_PAYMENTS_LIST, data.data);
                    }
                }
            );
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }

    @Action
    [Actions.GET_USER_REQUESTS_DATA](payload) {
        if (JwtService.getToken()) {
            ApiService.post("usertvs/user-requests-data", payload).then(({data}) => {
                if (data.success) {
                    this.context.commit(Mutations.SET_USER_REQUESTS_DATA, data.data);
                }
            });
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }

    @Action
    [Actions.ADD_NEW_REQUEST](payload) {
        if (JwtService.getToken()) {
            ApiService.post("usertvs/user-add-new-request", payload).then(
                ({data}) => {
                    if (data.success) {
                        this.context.commit(Mutations.SET_USER_REQUESTS_DATA, data.data);
                    }

                    if (payload.fn != undefined) {
                        payload.fn(data);
                    }
                }
            );
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }

    @Action
    [Actions.SET_REQUEST_RATING](payload) {
        if (JwtService.getToken()) {
            ApiService.post("usertvs/user-request-rating", payload).then(({data}) => {
                if (data.success) {
                    this.context.commit(Mutations.SET_USER_REQUESTS_DATA, data.data);
                }

                if (payload.fn != undefined) {
                    payload.fn(data);
                }
            });
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }

    @Action
    [Actions.CANCEL_REQUEST](payload) {
        if (JwtService.getToken()) {
            ApiService.post("usertvs/user-cancel-request", payload).then(({data}) => {
                if (data.success) {
                    this.context.commit(Mutations.SET_USER_REQUESTS_DATA, data.data);
                }

                if (payload.fn != undefined) {
                    payload.fn(data);
                }
            });
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }

    @Action
    [Actions.ADD_NEW_NOTE_TO_REQUEST](payload) {
        if (JwtService.getToken()) {
            ApiService.post("usertvs/user-add-new-note-to-request", payload).then(
                ({data}) => {
                    if (data.success) {
                        this.context.commit(Mutations.SET_USER_REQUESTS_DATA, data.data);
                    }

                    if (payload.fn != undefined) {
                        payload.fn(data);
                    }
                }
            );
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }

    @Action
    [Actions.REMOVE_AVATAR](payload) {
        if (JwtService.getToken()) {
            ApiService.post("usertvs/user-remove-avatar", payload).then(
                ({data}) => {
                    if (data.success) {
                        this.context.commit(Mutations.SET_USER, data.data);
                    }

                    if (payload.fn != undefined) {
                        payload.fn(data);
                    }
                }
            );
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }

    @Action
    [Actions.CHANGE_PROFILE_PARAMS](payload) {
        if (JwtService.getToken()) {
            ApiService.post("usertvs/user-change-params", payload).then(
                ({data}) => {
                    if (data.success) {
                        this.context.commit(Mutations.SET_USER, data.data);
                    }

                    if (payload.fn != undefined) {
                        payload.fn(data);
                    }
                }
            );
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }

    @Action
    [Actions.DISABLE_PROFILE](payload) {
        if (JwtService.getToken()) {
            ApiService.post("usertvs/user-disable-profile", payload).then(
                ({data}) => {
                    if (data.success) {
                        this.context.commit(Mutations.SET_USER, data.data);
                    }

                    if (payload.fn != undefined) {
                        payload.fn(data);
                    }
                }
            );
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }

    @Action
    [Actions.ENABLE_PROFILE](payload) {
        if (JwtService.getToken()) {
            ApiService.post("usertvs/user-enable-profile", payload).then(
                ({data}) => {
                    if (data.success) {
                        this.context.commit(Mutations.SET_USER, data.data);
                    }

                    if (payload.fn != undefined) {
                        payload.fn(data);
                    }
                }
            );
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }
}
