import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/overview",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "profile",
        name: "profile",
        alias: "/profile",
        component: () => import("@/components/page-layouts/Profile.vue"),
        children: [
          {
            path: "overview",
            name: "profile-overview",
            alias: "/overview",
            component: () =>
              import("@/views/crafted/pages/profile/Overview.vue"),
          },
          {
            path: "channels",
            name: "channels",
            alias: "/channels",
            component: () =>
              import("@/views/crafted/pages/profile/Channels.vue"),
          },
          {
            path: "payments",
            name: "payments",
            alias: "/payments",
            component: () =>
              import("@/views/crafted/pages/profile/Payments.vue"),
          },
          {
            path: "requests",
            name: "requests",
            alias: "/requests",
            component: () =>
              import("@/views/crafted/pages/profile/Requests.vue"),
          },
          {
            path: "drweb",
            name: "drweb",
            alias: "/drweb",
            component: () => import("@/views/crafted/pages/profile/DrWeb.vue"),
          },
          {
            path: "settings",
            name: "settings",
            alias: "/settings",
            component: () =>
              import("@/views/crafted/pages/profile/Settings.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  /*store
      .dispatch(Actions.LOGOUT);
  return;*/
  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
