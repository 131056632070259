enum Actions {
    // action types
    ADD_BODY_CLASSNAME = "addBodyClassName",
    REMOVE_BODY_CLASSNAME = "removeBodyClassName",
    ADD_BODY_ATTRIBUTE = "addBodyAttribute",
    REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
    ADD_CLASSNAME = "addClassName",
    VERIFY_AUTH = "verifyAuth",
    LOGIN = "login",
    LOGOUT = "logout",
    REGISTER = "register",
    UPDATE_USER = "updateUser",
    FORGOT_PASSWORD = "forgotPassword",
    SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
    //Получить тарифик пользователя
    GET_USER_DOWNLOAD_UPLOAD_TRAFFIC = "getUserDownloadUploadTraffic",
    //Активировать услугу Кредит
    ACTIVATE_CREDIT = "activateCredit",
    //Активировать услугу DrWeb
    ACTIVATE_DRWEB = "activateDrWeb",
    //Изменить тариф DrWeb
    CHANGE_DRWEB_TARIFF = "changeDrWebTariff",
    //Отключить DrWeb
    DISABLE_DRWEB_TARIFF = "disableDrWebTariff",
    //Изменить тарифный план
    CHANGE_TARIFF = "changeTariff",
    //Получить список проплат пользвоателя
    USER_PAYMENT_LIST = "userPaymentsList",
    //Запрос по балансу
    NO_MONEY_SEND_REQUEST = "noMoneySendRequest",
    //Получить список заявок
    GET_USER_REQUESTS_DATA = "getUserRequestsList",
    //Добавить новую заявку
    ADD_NEW_REQUEST = "addNewRequest",
    SET_REQUEST_RATING = "setRequestRating",
    CANCEL_REQUEST = "cancelUserRequest",
    ADD_NEW_NOTE_TO_REQUEST = "addNewNoteToRequest",
    //Avatar
    REMOVE_AVATAR = "removeUserAvatar",
    //Pfofile
    CHANGE_PROFILE_PARAMS = "changeProfileParams",
    //Disable profile
    DISABLE_PROFILE = "disableProfile",
    //Enable profile
    ENABLE_PROFILE = "enableProfile",

}

enum Mutations {
    // mutation types
    SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
    PURGE_AUTH = "logOut",
    SET_AUTH = "setAuth",
    SET_USER = "setUser",
    SET_PASSWORD = "setPassword",
    SET_ERROR = "setError",
    SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
    SET_LAYOUT_CONFIG = "setLayoutConfig",
    RESET_LAYOUT_CONFIG = "resetLayoutConfig",
    OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
    OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
    SET_DOWNLOAD_UPLOAD_USER_TRAFFIC = "downloadUploadUserTraffic",
    SET_USER_PAYMENTS_LIST = "setUserPaymentsList",
    SET_USER_REQUESTS_DATA = "setUserRequestsData",
}

export {Actions, Mutations};
